import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const MPHiringFunnelRBAC = {
  pageID: 'd987bc78d70165422db7d98cd018c862',
  elementID: {
    ViewHiringList: '106ebc484fdecbdebcf61047c0cab756',
    SingleAddHiring: 'b4d3bf68bfdf90124b39f9015848d7b0',
    BulkUploadHiringCapacity: '3d10dff1026c20ff272aa10ad0b62156',
    AssignPIC: '3770844223140793a785da411216d3c7',
    AddPrioritization: '9c122f190b236a330b84c980f79d8d06',
  },
} as const

export const useRBACMPHiringFunnelPage = createUseRBAC(MPHiringFunnelRBAC.elementID)

export type UseRBACMPHiringFunnelPageType = ReturnType<typeof useRBACMPHiringFunnelPage>

export const PrioritizationSettingRBAC = {
  pageID: '8e99217d72fb045cbc996eee4a16a311',
  elementID: {
    ViewPrioritizationList: '2eacc34ad53838c7db2ca491ba749a53',
    CreatePrioritization: '872a3065ee6120a67df6c42a0b6d3708',
    EditPrioritization: '93a19b5290e52f6370f41858369c6cf4',
    DeletePrioritization: 'd5465217ee6e8e4401597ec193daba5d',
  },
} as const

export const useRBACPrioritizationSettingPage = createUseRBAC(PrioritizationSettingRBAC.elementID)

export type UseRBACPrioritizationSettingPageType = ReturnType<
  typeof useRBACPrioritizationSettingPage
>
