import { Select } from '@astro-ui/components'
import styled from 'styled-components'
import useSelectLocation from './useSelectLocation'

const SelectLocation = () => {
  const { listLocation, handleSelectLocation, locationSelected } = useSelectLocation()

  return (
    <>
      <Select
        className="ml-16"
        value={locationSelected}
        options={listLocation}
        onChange={handleSelectLocation}
        placeholder="Pilih Hub"
        displayKey="location_name"
        withSearch
      />
      <Devider />
    </>
  )
}

export default SelectLocation

const Devider = styled.div`
  border: 1px solid #d6dfeb;
  margin: 0px 15px;
  width: 1px;
  height: 25px;
`
