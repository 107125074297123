import { Get } from 'config/apiServiceConfig'
import AxiosInstance from 'config/apiServiceApiGateway'

export type LocationType = {
  location_id: number
  location_name: string
  location_type: string
}

export type HiringFunnelPaginationType = {
  sort: string
  direction: string
  page_size: number
  page_index: number
  number_of_elements: number
  total_elements: number
  total_pages: number
}

export type FetchHiringFunnelQueryType = {
  page_index?: number
  page_size?: number
  name?: string
  location_id?: number[]
  role?: string[] | string
  employment_type?: string[]
  opening_start_date?: string
  opening_end_date?: string
  is_active?: string
  direction?: string
}

export type HiringFunnelType = {
  id: number
  job_id: string
  location_name: string
  location_id: number
  role: string
  employment_type: string
  staff_id: number
  staff_name: string
  opening_date: string
  fulfillment_date: string
  fulfilled_date: string
  assigned_pic_id: number
  assigned_pic_name: string
  hiring_status: boolean
  is_active: boolean
  is_approved: boolean
}

export type AssignPICType = {
  id: number
  email: string
  full_name: string
}

export type PostAssignPICPayloadType = {
  hiring_funnel_ids: number[]
  user_id: number
}

export type CreateHiringFunnelPayloadType = {
  location_id: number
  details: { role: string; employment_type: string; qty: number; hiring_status: boolean }[]
}

export type PrioritizationSettingType = {
  id?: number
  location_ids?: number[]
  location_names?: string[]
  role: string
  weight: number
  employment_type: string
  location_type: string
}

export interface GetHiringFunnelResponseInterface {
  data: HiringFunnelType[]
  pagination: HiringFunnelPaginationType
}

export interface GetAssignPICResponseInterface {
  data: {
    users: AssignPICType[]
  }
}

export interface GetPrioritizationSettingResponseInterface {
  data: PrioritizationSettingType[]
  pagination: HiringFunnelPaginationType
  message: string
}

export type ChangeStatusPayloadType = {
  id: number
  status: boolean
}

export const getAllLocationsAPI = () => Get<LocationType[]>({ url: '/api/locations/all' })

export const getHiringFunnelAPI = (data: FetchHiringFunnelQueryType) =>
  AxiosInstance.Post<GetHiringFunnelResponseInterface, true>({
    url: '/staff-management/internal/:version/hiring-funnel/get-with-pagination',
    version: 'v1',
    data,
  })

export const createHiringFunnelAPI = (data: CreateHiringFunnelPayloadType) =>
  AxiosInstance.Post<{ message: string }, true>({
    url: '/staff-management/internal/:version/hiring-funnel',
    version: 'v1',
    data,
  })

export const getAssignPICAPI = () =>
  AxiosInstance.Get<GetAssignPICResponseInterface, true>({
    url: '/accounts/internal/:version/user/location/role?page_index=0&page_size=9999&roles=SOURCING',
    version: 'v1',
  })

export const postAssignPICAPI = (data: PostAssignPICPayloadType) =>
  AxiosInstance.Post<{ message: string }, true>({
    url: '/staff-management/internal/:version/hiring-funnel/assign-pic',
    version: 'v1',
    data,
  })

export const getPrioritizationSettingAPI = (data: FetchHiringFunnelQueryType) =>
  AxiosInstance.Post<GetPrioritizationSettingResponseInterface, true>({
    url: '/staff-management/internal/:version/hiring-funnel/prioritization/get-with-pagination',
    version: 'v1',
    data,
  })

export const postPrioritizationSettingAPI = (data: PrioritizationSettingType) =>
  AxiosInstance.Post<GetPrioritizationSettingResponseInterface, true>({
    url: '/staff-management/internal/:version/hiring-funnel/prioritization',
    version: 'v1',
    data,
  })

export const putPrioritizationSettingAPI = (data: PrioritizationSettingType) =>
  AxiosInstance.Put<{ message: string }, true>({
    url: '/staff-management/internal/:version/hiring-funnel/prioritization/:id',
    version: 'v1',
    data,
    urlParams: { id: data.id },
  })

export const deletePrioritizationSettingAPI = (id: number) =>
  AxiosInstance.Delete<{ message: string }, true>({
    url: '/staff-management/internal/:version/hiring-funnel/prioritization/:id',
    version: 'v1',
    urlParams: { id },
  })

export const putChangeHiringStatusAPI = (data: ChangeStatusPayloadType) =>
  AxiosInstance.Put<{ message: string }, true>({
    url: '/staff-management/internal/:version/hiring-funnel/:id/hiring-status',
    version: 'v1',
    data,
    urlParams: { id: data.id },
  })

export const putChangeStatusAPI = (data: ChangeStatusPayloadType) =>
  AxiosInstance.Put<{ message: string }, true>({
    url: '/staff-management/internal/:version/hiring-funnel/:id/status',
    version: 'v1',
    data,
    urlParams: { id: data.id },
  })

export const fetchHiringFunnelTemplate = () =>
  AxiosInstance.Get({
    url: '/staff-management/internal/:version/hiring-funnel/prioritization/template',
    version: 'v1',
  })

export const postHiringFunnelBulkUpload = (data: { file: File; updated_by: string }) =>
  AxiosInstance.Post({
    url: '/staff-management/internal/:version/hiring-funnel/prioritization/upload',
    version: 'v1',
    data,
    config: {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'multipart/form-data',
      },
    },
    convertResponse: true,
  })
