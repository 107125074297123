/* eslint-disable @typescript-eslint/naming-convention */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { DateRangeType } from 'components/DatePicker/useDatePicker'
import { Product, SkuRegistration } from 'features/Product/@types'
import { BrandItemType, GetBrandDetailByIdResponseType } from 'features/Product/services/brand'
import {
  GetProductAttributeOptionResponseType,
  GetProductTypeResponseType,
  GetCategoriesChildResponseType,
} from 'features/Product/services/product'
import { GetSkuRegistrationListParamsType } from 'features/Product/services/skuRegistration'
import {
  ClusterItemType,
  GetLocationListByTypeResponseType,
} from 'features/Location/@types/location'
import { VendorDetailsInterface } from 'features/Enterprise/Vendor/services/addEditVendor'
import { DiscoveryLabelType } from 'utils/apiList/dicoveryLabel'
import {
  initialStateGeneralDataForm,
  initialStateHandlingDataForm,
  initialStateGrammationForm,
  initialStatePricingForm,
  initialStateAdditionalForm,
  initialStateSellingDetailForm,
  initialStateExchangeProductForm,
  initialStateAssortmentDetailForm,
  initialStateAssortmentPlanningForm,
  initialStateSkuAssetForm,
  initialErrorGeneralForm,
  initialErrorHandlingForm,
  initialErrorGrammationForm,
  initialErrorPricingForm,
  initialStateErrorAdditionalForm,
  initialStateErrorSellingDetailForm,
  initialStateErrorSkuPortfolio,
  initialStateFailedDataCreationForm,
} from './initialState'

export type LocationListType = {
  id: number
  name: string
  type: string
}

export type ProductImageType = {
  id: string
  file: Nullable<File>
  previewUrl: string
  pathLarge: string
  pathMedium: string
  pathSmall: string
}

type UomListType = Pick<
  Product.GetUomResponseType['unit_of_measurements']['content'][number],
  'id' | 'description'
> & {
  label: string
}

export type BrandListType = (BrandItemType & { label: string })[]
export type SkuRegistrationInitialStateType = {
  selectedId: string
  paginationData: PaginationType
  skuRegistrationList: SkuRegistration.GetSkuRegistrationListResponseType['data']
  shouldClearSearchKeyword: boolean
  params: GetSkuRegistrationListParamsType
  selectedStatus: Nullable<SkuRegistration.ConstantItemType>
  selectedDateRange: {
    from: Date | null
    to: Date | null
  }
  selectedCreator: Nullable<SkuRegistration.GetCreatorsSkuRegistrationResponseType['data'][number]>
  creatorList: SkuRegistration.GetCreatorsSkuRegistrationResponseType['data']
  vendorList: {
    id: number
    company_name: string
  }[]
  brandList: BrandListType
  brandDetail: Nullable<GetBrandDetailByIdResponseType>
  ppnList: { id: number; ppn: number }[]
  businessTaggingList: GetProductAttributeOptionResponseType['data']
  productTypeList: GetProductTypeResponseType['data']
  categoryL1List: GetCategoriesChildResponseType[]
  categoryL2List: Product.CategoryL1Type[]
  selectedCategoryL1: Nullable<GetCategoriesChildResponseType>
  selectedCategoryL2: Nullable<Product.CategoryL1Type>
  inboundWarehouseList: LocationListType[]
  constant: SkuRegistration.GetAllConstantType
  skuRegistrationDetail: Nullable<SkuRegistration.SkuRegistrationItemType>
  labelImageList: DiscoveryLabelType[]
  labelPromotionList: DiscoveryLabelType[]
  modalUpdateSku: {
    isOpen: boolean
    selectedTab: string
    isAutoGenerateBarcode: boolean
    selectedSpecialHandlingDetail: {
      key: string
      label: string
    }[]
    generalDataForm: SkuRegistration.GeneralDataType
    handlingDataForm: SkuRegistration.HandlingType
    grammationForm: SkuRegistration.GrammationType
    pricingForm: SkuRegistration.PricingType
    errorGeneralForm: SkuRegistration.ErrorGeneralFormType
    errorHandlingForm: SkuRegistration.ErrorHandlingFormType
    errorGrammationForm: SkuRegistration.ErrorGrammationFormType
    errorPricingForm: SkuRegistration.ErrorPricingFormType
  }
  modalAdditionalDetails: {
    isOpen: boolean
    selectedTab: string
    selectedPromotionTermPeriod: DateRangeType['value']
    errorAdditionalForm: SkuRegistration.ErrorAdditionalFormType
    errorSellingDetailForm: SkuRegistration.ErrorSellingDetailFormType
    additionalForm: SkuRegistration.AdditionalInformationType
    sellingDetailForm: SkuRegistration.SellingDetailType
  }
  modalApproveSku: {
    isOpen: boolean
    selectedTab: string
    isRejecting: {
      generalData: boolean
      handling: boolean
      grammation: boolean
      pricing: boolean
      additionalData: boolean
      sellingDetail: boolean
      exchangeProduct: boolean
      assortmentDetail: boolean
      assortmentPlanning: boolean
      skuAsset: boolean
    }
    additionalFlaggingRejected: boolean
    error: {
      reject_reason: string
    }
  }
  isOpenModalRejectReason: boolean
  isOpenModalCancelSkuRegis: boolean
  modalSkuPortfolio: {
    isOpen: boolean
    selectedTab: string
    errorSkuPortfolio: SkuRegistration.ErrorSkuPortfolioType
    isDisabledClusterOrHubLevel: boolean
    exchangeProductForm: SkuRegistration.ProductSubtitutionType
    assortmentDetailForm: SkuRegistration.AssortmentDetailType
    assortmentPlanningForm: SkuRegistration.AssortmentPlanningType
    selectedCluster: ClusterItemType[]
    isOpenModalLocationByCluster: boolean
  }
  modalSkuMerchandising: {
    isOpen: boolean
    needToInitializeData: boolean
    selectedTab: string
    productImageList: ProductImageType[]
    productDescription: string
    errorSkuAssetForm: {
      image_paths: string
      name: string
      description: string
    }
    skuAssetForm: SkuRegistration.AssetType
  }
  modalFailedDataCreation: {
    isOpen: boolean
    failedDataCreationForm: SkuRegistration.FailedMasterDataCreationType
    errorFailedDataCreation: SkuRegistration.ErrorFailedDataCreationType
  }
  productExchangeInfoDetail: Nullable<Product.ProductExchangeInfoDetailType>
  modalConfirmSubmit: {
    isOpen: boolean
    source: string
  }
  itemUomList: UomListType[]
  volumeUomList: UomListType[]
  weightUomList: UomListType[]
  lengthUomList: UomListType[]
  productList: Product.ProductListType
  hubLevelList: { id: number; name: string; type: string }[]
  vendorDetail: Nullable<VendorDetailsInterface>
  clusterList: ClusterItemType[]
  modalSelectLocation: {
    isOpen: boolean
    source: string
    selectedLocationType: string
    selectedLocationIds: number[]
    selectedLocation: {
      id: number
      name: string
      type: string
    }[]
    locationTypeByBusinessTagging: string[]
    locationList: GetLocationListByTypeResponseType[]
    removeByType: string[]
  }
}

const initialState: SkuRegistrationInitialStateType = {
  selectedId: '',
  paginationData: {
    direction: 'desc',
    page_size: 20,
    number_of_elements: 0,
    total_elements: 0,
    sort: '',
    page_index: 1,
  },
  skuRegistrationList: [],
  shouldClearSearchKeyword: false,
  params: {
    'pagination.page_index': 1,
    'pagination.page_size': 20,
    'pagination.direction': 'asc',
  },
  selectedStatus: null,
  selectedDateRange: {
    from: null,
    to: null,
  },
  selectedCreator: null,
  creatorList: [],
  vendorList: [],
  brandList: [],
  brandDetail: null,
  ppnList: [],
  businessTaggingList: [],
  productTypeList: [],
  categoryL1List: [],
  categoryL2List: [],
  selectedCategoryL1: null,
  selectedCategoryL2: null,
  inboundWarehouseList: [],
  constant: {
    product_special_handling: [],
    product_registration_statuses: [],
    product_shelves: [],
    product_image_host: '',
    social_media_list: [],
    cluster: [],
    pap_location_types: [],
  },
  skuRegistrationDetail: null,
  labelImageList: [],
  labelPromotionList: [],
  modalUpdateSku: {
    isOpen: false,
    selectedTab: 'generalData',
    isAutoGenerateBarcode: false,
    selectedSpecialHandlingDetail: [],
    generalDataForm: initialStateGeneralDataForm,
    handlingDataForm: initialStateHandlingDataForm,
    grammationForm: initialStateGrammationForm,
    pricingForm: initialStatePricingForm,
    errorGeneralForm: initialErrorGeneralForm,
    errorHandlingForm: initialErrorHandlingForm,
    errorPricingForm: initialErrorPricingForm,
    errorGrammationForm: initialErrorGrammationForm,
  },
  modalAdditionalDetails: {
    isOpen: false,
    selectedTab: 'additionalData',
    selectedPromotionTermPeriod: [null, null],
    errorAdditionalForm: initialStateErrorAdditionalForm,
    errorSellingDetailForm: initialStateErrorSellingDetailForm,
    additionalForm: initialStateAdditionalForm,
    sellingDetailForm: initialStateSellingDetailForm,
  },
  modalApproveSku: {
    isOpen: false,
    selectedTab: '',
    isRejecting: {
      generalData: false,
      handling: false,
      grammation: false,
      pricing: false,
      additionalData: false,
      sellingDetail: false,
      exchangeProduct: false,
      assortmentDetail: false,
      assortmentPlanning: false,
      skuAsset: false,
    },
    additionalFlaggingRejected: false,
    error: {
      reject_reason: '',
    },
  },
  isOpenModalRejectReason: false,
  isOpenModalCancelSkuRegis: false,
  modalSkuPortfolio: {
    isOpen: false,
    selectedTab: 'exchangeProduct',
    errorSkuPortfolio: initialStateErrorSkuPortfolio,
    isDisabledClusterOrHubLevel: false,
    exchangeProductForm: initialStateExchangeProductForm,
    assortmentDetailForm: initialStateAssortmentDetailForm,
    assortmentPlanningForm: initialStateAssortmentPlanningForm,
    selectedCluster: [],
    isOpenModalLocationByCluster: false,
  },
  modalSkuMerchandising: {
    isOpen: false,
    needToInitializeData: false,
    selectedTab: 'skuAsset',
    productImageList: [],
    productDescription: '',
    errorSkuAssetForm: {
      image_paths: '',
      name: '',
      description: '',
    },
    skuAssetForm: initialStateSkuAssetForm,
  },
  modalFailedDataCreation: {
    isOpen: false,
    failedDataCreationForm: initialStateFailedDataCreationForm,
    errorFailedDataCreation: {
      product_name: '',
      primary_grammation_barcode: '',
      secondary_grammation_barcode: '',
    },
  },
  productExchangeInfoDetail: null,
  modalConfirmSubmit: {
    isOpen: false,
    source: '',
  },
  itemUomList: [],
  volumeUomList: [],
  weightUomList: [],
  lengthUomList: [],
  productList: [],
  hubLevelList: [],
  vendorDetail: null,
  clusterList: [],
  modalSelectLocation: {
    isOpen: false,
    source: '',
    selectedLocationType: '',
    locationTypeByBusinessTagging: [],
    selectedLocationIds: [],
    selectedLocation: [],
    locationList: [],
    removeByType: [],
  },
}

const skuRegistration = createSlice({
  name: 'skuRegistration',
  initialState,
  reducers: {
    resetState: () => initialState,
    setSelectedId: (state, action: PayloadAction<string>) => {
      state.selectedId = action.payload
    },
    setShouldClearSearchKeyword: (state, action: PayloadAction<boolean>) => {
      state.shouldClearSearchKeyword = action.payload
    },
    setParams: (
      state,
      action: PayloadAction<Partial<SkuRegistrationInitialStateType['params']>>,
    ) => {
      state.params = {
        ...state.params,
        ...action.payload,
      }
    },
    resetParams: (state) => {
      state.params = initialState.params
    },
    setSelectedStatus: (
      state,
      action: PayloadAction<SkuRegistrationInitialStateType['selectedStatus']>,
    ) => {
      state.selectedStatus = action.payload
    },
    resetSelectedStatus: (state) => {
      state.selectedStatus = initialState.selectedStatus
    },
    setSelectedCategoryL1: (
      state,
      action: PayloadAction<SkuRegistrationInitialStateType['selectedCategoryL1']>,
    ) => {
      state.selectedCategoryL1 = action.payload
    },
    setSelectedCategoryL2: (
      state,
      action: PayloadAction<SkuRegistrationInitialStateType['selectedCategoryL2']>,
    ) => {
      state.selectedCategoryL2 = action.payload
    },
    setSkuRegistrationDetailOnFetchSuccess: (
      state,
      action: PayloadAction<SkuRegistration.SkuRegistrationItemType>,
    ) => {
      state.skuRegistrationDetail = action.payload
    },
    setSkuRegistrationDetail: (
      state,
      action: PayloadAction<Partial<SkuRegistration.SkuRegistrationItemType>>,
    ) => {
      if (state.skuRegistrationDetail) {
        state.skuRegistrationDetail = {
          ...state.skuRegistrationDetail,
          ...action.payload,
        }
      }
    },
    updateIsRejectedOnGeneralData: (
      state,
      action: PayloadAction<Partial<SkuRegistration.SkuRegistrationItemType['general_data']>>,
    ) => {
      if (state.skuRegistrationDetail) {
        state.skuRegistrationDetail.general_data = {
          ...state.skuRegistrationDetail.general_data,
          ...action.payload,
        }
      }
    },
    updateIsRejectedOnHandlingData: (
      state,
      action: PayloadAction<Partial<SkuRegistration.SkuRegistrationItemType['handling']>>,
    ) => {
      if (state.skuRegistrationDetail) {
        state.skuRegistrationDetail.handling = {
          ...state.skuRegistrationDetail.handling,
          ...action.payload,
        }
      }
    },
    updateIsRejectedOnGrammation: (
      state,
      action: PayloadAction<Partial<SkuRegistration.SkuRegistrationItemType['grammation']>>,
    ) => {
      if (state.skuRegistrationDetail) {
        state.skuRegistrationDetail.grammation = {
          ...state.skuRegistrationDetail.grammation,
          ...action.payload,
        }
      }
    },
    updateIsRejectedOnGrammationPrimary: (
      state,
      action: PayloadAction<Partial<SkuRegistration.GrammationPrimarySubmitType>>,
    ) => {
      if (state.skuRegistrationDetail) {
        state.skuRegistrationDetail.grammation.primary = {
          ...state.skuRegistrationDetail.grammation.primary,
          ...action.payload,
        }
      }
    },
    updateIsRejectedOnGrammationSecondary: (
      state,
      action: PayloadAction<Partial<SkuRegistration.GrammationSecondarySubmitType>>,
    ) => {
      if (state.skuRegistrationDetail && state.skuRegistrationDetail.grammation.secondary) {
        state.skuRegistrationDetail.grammation.secondary = {
          ...state.skuRegistrationDetail.grammation.secondary,
          ...action.payload,
        }
      }
    },
    updateIsRejectedOnPricing: (
      state,
      action: PayloadAction<Partial<SkuRegistration.SkuRegistrationItemType['pricing']>>,
    ) => {
      if (state.skuRegistrationDetail) {
        state.skuRegistrationDetail.pricing = {
          ...state.skuRegistrationDetail.pricing,
          ...action.payload,
        }
      }
    },
    updateIsRejectedOnAdditionalData: (
      state,
      action: PayloadAction<
        Partial<SkuRegistration.SkuRegistrationItemType['additional_information']>
      >,
    ) => {
      if (state.skuRegistrationDetail) {
        state.skuRegistrationDetail.additional_information = {
          ...state.skuRegistrationDetail.additional_information,
          ...action.payload,
        }
      }
    },
    updateIsRejectedOnSellingDetail: (
      state,
      action: PayloadAction<Partial<SkuRegistration.SkuRegistrationItemType['selling_detail']>>,
    ) => {
      if (state.skuRegistrationDetail) {
        state.skuRegistrationDetail.selling_detail = {
          ...state.skuRegistrationDetail.selling_detail,
          ...action.payload,
        }
      }
    },
    updateIsRejectedOnSkuAsset: (
      state,
      action: PayloadAction<Partial<SkuRegistration.SkuRegistrationItemType['asset']>>,
    ) => {
      if (state.skuRegistrationDetail) {
        state.skuRegistrationDetail.asset = {
          ...state.skuRegistrationDetail.asset,
          ...action.payload,
        }
      }
    },
    updateIsRejectedOnExchangeProduct: (
      state,
      action: PayloadAction<
        Partial<SkuRegistration.SkuRegistrationItemType['product_substitution']>
      >,
    ) => {
      if (state.skuRegistrationDetail) {
        state.skuRegistrationDetail.product_substitution = {
          ...state.skuRegistrationDetail.product_substitution,
          ...action.payload,
        }
      }
    },
    updateIsRejectedOnAssortmentDetail: (
      state,
      action: PayloadAction<Partial<SkuRegistration.SkuRegistrationItemType['assortment_detail']>>,
    ) => {
      if (state.skuRegistrationDetail) {
        state.skuRegistrationDetail.assortment_detail = {
          ...state.skuRegistrationDetail.assortment_detail,
          ...action.payload,
        }
      }
    },
    updateIsRejectedOnAssortmentPlanning: (
      state,
      action: PayloadAction<
        Partial<SkuRegistration.SkuRegistrationItemType['assortment_planning']>
      >,
    ) => {
      if (state.skuRegistrationDetail) {
        state.skuRegistrationDetail.assortment_planning = {
          ...state.skuRegistrationDetail.assortment_planning,
          ...action.payload,
        }
      }
    },
    setBusinessTaggingList: (
      state,
      action: PayloadAction<SkuRegistrationInitialStateType['businessTaggingList']>,
    ) => {
      state.businessTaggingList = action.payload
    },
    setProductTypeList: (
      state,
      action: PayloadAction<SkuRegistrationInitialStateType['productTypeList']>,
    ) => {
      state.productTypeList = action.payload
    },
    setCategories: (state, action) => {
      const { type, listData } = action.payload
      if (type === 'L1') {
        state.categoryL1List = listData
      } else if (type === 'L2') {
        state.categoryL2List = listData
      }
    },
    setConstant: (state, action: PayloadAction<SkuRegistration.GetAllConstantType>) => {
      state.constant = action.payload
    },
    setSkuRegistrationList: (
      state,
      action: PayloadAction<{
        data: SkuRegistrationInitialStateType['skuRegistrationList']
        pagination: SkuRegistrationInitialStateType['paginationData']
      }>,
    ) => {
      const { data, pagination } = action.payload
      state.skuRegistrationList = data
      state.paginationData = pagination
    },
    setVendorList: (
      state,
      action: PayloadAction<SkuRegistrationInitialStateType['vendorList']>,
    ) => {
      state.vendorList = action.payload
    },
    setClusterList: (state, action: PayloadAction<ClusterItemType[]>) => {
      state.clusterList = action.payload
    },
    setBrandList: (state, action: PayloadAction<SkuRegistrationInitialStateType['brandList']>) => {
      state.brandList = action.payload
    },
    setBrandDetail: (state, action: PayloadAction<Nullable<GetBrandDetailByIdResponseType>>) => {
      state.brandDetail = action.payload
    },
    setPpnList: (state, action: PayloadAction<SkuRegistrationInitialStateType['ppnList']>) => {
      state.ppnList = action.payload
    },
    setModalUpdateSku: (
      state,
      action: PayloadAction<Partial<SkuRegistrationInitialStateType['modalUpdateSku']>>,
    ) => {
      state.modalUpdateSku = {
        ...state.modalUpdateSku,
        ...action.payload,
      }
    },
    resetModalUpdateSku: (state) => {
      state.modalUpdateSku = initialState.modalUpdateSku
    },
    setSelectedDateRange: (
      state,
      action: PayloadAction<SkuRegistrationInitialStateType['selectedDateRange']>,
    ) => {
      state.selectedDateRange = action.payload
    },
    resetSelectedDateRange: (state) => {
      state.selectedDateRange = initialState.selectedDateRange
    },
    setSelectedCreator: (
      state,
      action: PayloadAction<SkuRegistrationInitialStateType['selectedCreator']>,
    ) => {
      state.selectedCreator = action.payload
    },
    setCreatorList: (
      state,
      action: PayloadAction<SkuRegistrationInitialStateType['creatorList']>,
    ) => {
      state.creatorList = action.payload
    },
    setErrorGeneralForm: (
      state,
      action: PayloadAction<Partial<SkuRegistration.ErrorGeneralFormType>>,
    ) => {
      state.modalUpdateSku.errorGeneralForm = {
        ...state.modalUpdateSku.errorGeneralForm,
        ...action.payload,
      }
    },
    setErrorHandlingForm: (
      state,
      action: PayloadAction<Partial<SkuRegistration.ErrorHandlingFormType>>,
    ) => {
      state.modalUpdateSku.errorHandlingForm = {
        ...state.modalUpdateSku.errorHandlingForm,
        ...action.payload,
      }
    },
    setErrorGrammationForm: (
      state,
      action: PayloadAction<Partial<SkuRegistration.ErrorGrammationFormType>>,
    ) => {
      state.modalUpdateSku.errorGrammationForm = {
        ...state.modalUpdateSku.errorGrammationForm,
        ...action.payload,
      }
    },
    setErrorPricingForm: (
      state,
      action: PayloadAction<Partial<SkuRegistration.ErrorPricingFormType>>,
    ) => {
      state.modalUpdateSku.errorPricingForm = {
        ...state.modalUpdateSku.errorPricingForm,
        ...action.payload,
      }
    },
    setGeneralDataForm: (
      state,
      action: PayloadAction<Partial<SkuRegistration.GeneralDataType>>,
    ) => {
      state.modalUpdateSku.generalDataForm = {
        ...state.modalUpdateSku.generalDataForm,
        ...action.payload,
      }
    },
    setHandlingDataForm: (state, action: PayloadAction<Partial<SkuRegistration.HandlingType>>) => {
      state.modalUpdateSku.handlingDataForm = {
        ...state.modalUpdateSku.handlingDataForm,
        ...action.payload,
      }
    },
    setGrammationForm: (state, action: PayloadAction<Partial<SkuRegistration.GrammationType>>) => {
      state.modalUpdateSku.grammationForm = {
        ...state.modalUpdateSku.grammationForm,
        ...action.payload,
      }
    },
    setGrammationPrimaryForm: (
      state,
      action: PayloadAction<Partial<SkuRegistration.GrammationPrimarySecondaryType>>,
    ) => {
      state.modalUpdateSku.grammationForm.primary = {
        ...state.modalUpdateSku.grammationForm.primary,
        ...action.payload,
      }
    },
    setGrammationSecondaryForm: (
      state,
      action: PayloadAction<Partial<SkuRegistration.GrammationPrimarySecondaryType>>,
    ) => {
      state.modalUpdateSku.grammationForm.secondary = {
        ...state.modalUpdateSku.grammationForm.secondary,
        ...action.payload,
      }
    },
    setPricingForm: (state, action: PayloadAction<Partial<SkuRegistration.PricingType>>) => {
      state.modalUpdateSku.pricingForm = {
        ...state.modalUpdateSku.pricingForm,
        ...action.payload,
      }
    },
    setAdditionalForm: (
      state,
      action: PayloadAction<Partial<SkuRegistration.AdditionalInformationType>>,
    ) => {
      state.modalAdditionalDetails.additionalForm = {
        ...state.modalAdditionalDetails.additionalForm,
        ...action.payload,
      }
    },
    setSellingDetailForm: (
      state,
      action: PayloadAction<Partial<SkuRegistration.SellingDetailType>>,
    ) => {
      state.modalAdditionalDetails.sellingDetailForm = {
        ...state.modalAdditionalDetails.sellingDetailForm,
        ...action.payload,
      }
    },
    setErrorAdditionalForm: (
      state,
      action: PayloadAction<
        Partial<SkuRegistrationInitialStateType['modalAdditionalDetails']['errorAdditionalForm']>
      >,
    ) => {
      state.modalAdditionalDetails.errorAdditionalForm = {
        ...state.modalAdditionalDetails.errorAdditionalForm,
        ...action.payload,
      }
    },
    setErrorSellingDetailForm: (
      state,
      action: PayloadAction<
        Partial<SkuRegistrationInitialStateType['modalAdditionalDetails']['errorSellingDetailForm']>
      >,
    ) => {
      state.modalAdditionalDetails.errorSellingDetailForm = {
        ...state.modalAdditionalDetails.errorSellingDetailForm,
        ...action.payload,
      }
    },
    setLocationList: (
      state,
      action: PayloadAction<{
        fieldName: 'inboundWarehouseList'
        listData: LocationListType[]
      }>,
    ) => {
      const { fieldName, listData } = action.payload
      state[fieldName] = listData
    },
    setHubLevelList: (
      state,
      action: PayloadAction<SkuRegistrationInitialStateType['hubLevelList']>,
    ) => {
      state.hubLevelList = action.payload
    },
    setModalAdditionalDetails: (
      state,
      action: PayloadAction<Partial<SkuRegistrationInitialStateType['modalAdditionalDetails']>>,
    ) => {
      state.modalAdditionalDetails = {
        ...state.modalAdditionalDetails,
        ...action.payload,
      }
    },
    resetModalAdditionalDetails: (state) => {
      state.modalAdditionalDetails = initialState.modalAdditionalDetails
    },
    setModalApproveSku: (
      state,
      action: PayloadAction<Partial<SkuRegistrationInitialStateType['modalApproveSku']>>,
    ) => {
      state.modalApproveSku = {
        ...state.modalApproveSku,
        ...action.payload,
      }
    },
    resetIsRejectingModalApproveSku: (state) => {
      state.modalApproveSku.isRejecting = initialState.modalApproveSku.isRejecting
    },
    setIsOpenModalRejectReason: (state, action: PayloadAction<boolean>) => {
      state.isOpenModalRejectReason = action.payload
    },
    resetModalApproveSku: (state) => {
      state.modalApproveSku = initialState.modalApproveSku
    },
    setErrorSkuPortfolio: (
      state,
      action: PayloadAction<
        Partial<SkuRegistrationInitialStateType['modalSkuPortfolio']['errorSkuPortfolio']>
      >,
    ) => {
      state.modalSkuPortfolio.errorSkuPortfolio = {
        ...state.modalSkuPortfolio.errorSkuPortfolio,
        ...action.payload,
      }
    },
    setModalSkuPortfolio: (
      state,
      action: PayloadAction<Partial<SkuRegistrationInitialStateType['modalSkuPortfolio']>>,
    ) => {
      state.modalSkuPortfolio = {
        ...state.modalSkuPortfolio,
        ...action.payload,
      }
    },
    resetModalSkuPortfolio: (state) => {
      state.modalSkuPortfolio = initialState.modalSkuPortfolio
    },
    setExchangeProductFormData: (
      state,
      action: PayloadAction<Partial<SkuRegistration.ProductSubtitutionType>>,
    ) => {
      state.modalSkuPortfolio.exchangeProductForm = {
        ...state.modalSkuPortfolio.exchangeProductForm,
        ...action.payload,
      }
    },
    setAssortmentDetailForm: (
      state,
      action: PayloadAction<Partial<SkuRegistration.AssortmentDetailType>>,
    ) => {
      state.modalSkuPortfolio.assortmentDetailForm = {
        ...state.modalSkuPortfolio.assortmentDetailForm,
        ...action.payload,
      }
    },
    setAssortmentPlanningForm: (
      state,
      action: PayloadAction<Partial<SkuRegistration.AssortmentPlanningType>>,
    ) => {
      state.modalSkuPortfolio.assortmentPlanningForm = {
        ...state.modalSkuPortfolio.assortmentPlanningForm,
        ...action.payload,
      }
    },
    setLabelList: (
      state,
      action: PayloadAction<{
        fieldName: 'labelImageList' | 'labelPromotionList'
        listData: DiscoveryLabelType[]
      }>,
    ) => {
      const { fieldName, listData } = action.payload
      state[fieldName] = listData
    },
    setProductImageList: (
      state,
      action: PayloadAction<
        SkuRegistrationInitialStateType['modalSkuMerchandising']['productImageList']
      >,
    ) => {
      state.modalSkuMerchandising.productImageList = action.payload
    },
    setErrorSkuAssetForm: (
      state,
      action: PayloadAction<
        Partial<SkuRegistrationInitialStateType['modalSkuMerchandising']['errorSkuAssetForm']>
      >,
    ) => {
      state.modalSkuMerchandising.errorSkuAssetForm = {
        ...state.modalSkuMerchandising.errorSkuAssetForm,
        ...action.payload,
      }
    },
    setModalSkuMerchandising: (
      state,
      action: PayloadAction<Partial<SkuRegistrationInitialStateType['modalSkuMerchandising']>>,
    ) => {
      state.modalSkuMerchandising = {
        ...state.modalSkuMerchandising,
        ...action.payload,
      }
    },
    resetModalSkuMerchandising: (state) => {
      state.modalSkuMerchandising = initialState.modalSkuMerchandising
    },
    setSkuAssetForm: (state, action: PayloadAction<Partial<SkuRegistration.AssetType>>) => {
      state.modalSkuMerchandising.skuAssetForm = {
        ...state.modalSkuMerchandising.skuAssetForm,
        ...action.payload,
      }
    },
    setUomList: (
      state,
      action: PayloadAction<{
        type: Product.UomGroupType
        listData: UomListType[]
      }>,
    ) => {
      const { type, listData } = action.payload
      if (type === 'ITEM') {
        state.itemUomList = listData
      } else if (type === 'VOLUME') {
        state.volumeUomList = listData
      } else if (type === 'WEIGHT') {
        state.weightUomList = listData
      } else if (type === 'LENGTH') {
        state.lengthUomList = listData
      }
    },
    setVendorDetail: (state, action: PayloadAction<VendorDetailsInterface>) => {
      state.vendorDetail = action.payload
    },
    setProductExchangeInfoDetail: (
      state,
      action: PayloadAction<Nullable<Product.ProductExchangeInfoDetailType>>,
    ) => {
      state.productExchangeInfoDetail = action.payload
    },
    setProductList: (state, action: PayloadAction<Product.ProductListType>) => {
      state.productList = action.payload
    },
    setIsOpenModalCancelSkuRegis: (state, action: PayloadAction<boolean>) => {
      state.isOpenModalCancelSkuRegis = action.payload
    },
    setModalConfirmSubmit: (
      state,
      action: PayloadAction<SkuRegistrationInitialStateType['modalConfirmSubmit']>,
    ) => {
      state.modalConfirmSubmit = {
        ...state.modalConfirmSubmit,
        ...action.payload,
      }
    },
    setModalFailedDataCreation: (
      state,
      action: PayloadAction<Partial<SkuRegistrationInitialStateType['modalFailedDataCreation']>>,
    ) => {
      state.modalFailedDataCreation = {
        ...state.modalFailedDataCreation,
        ...action.payload,
      }
    },
    setFailedDataCreationForm: (
      state,
      action: PayloadAction<
        Partial<
          SkuRegistrationInitialStateType['modalFailedDataCreation']['failedDataCreationForm']
        >
      >,
    ) => {
      state.modalFailedDataCreation.failedDataCreationForm = {
        ...state.modalFailedDataCreation.failedDataCreationForm,
        ...action.payload,
      }
    },
    setErrorFailedDataCreation: (
      state,
      action: PayloadAction<Partial<SkuRegistration.ErrorFailedDataCreationType>>,
    ) => {
      state.modalFailedDataCreation.errorFailedDataCreation = {
        ...state.modalFailedDataCreation.errorFailedDataCreation,
        ...action.payload,
      }
    },
    resetModalFailedDataCreation: (state) => {
      state.modalFailedDataCreation = initialState.modalFailedDataCreation
    },
    setModalSelectLocation: (
      state,
      action: PayloadAction<Partial<SkuRegistrationInitialStateType['modalSelectLocation']>>,
    ) => {
      state.modalSelectLocation = {
        ...state.modalSelectLocation,
        ...action.payload,
      }
    },
    resetModalSelectLocation: (state) => {
      state.modalSelectLocation = initialState.modalSelectLocation
    },
    manageSelectedLocationOfModalSelectLocation: (
      state,
      action: PayloadAction<GetLocationListByTypeResponseType>,
    ) => {
      const location = action.payload
      const locationId = location.location_id
      if (state.modalSelectLocation.selectedLocationIds.includes(locationId)) {
        const listIds = state.modalSelectLocation.selectedLocationIds.filter(
          (id) => id !== locationId,
        )
        const listLocation = state.modalSelectLocation.selectedLocation.filter(
          (item) => item.id !== locationId,
        )
        state.modalSelectLocation.selectedLocationIds = listIds
        state.modalSelectLocation.selectedLocation = listLocation
      } else {
        state.modalSelectLocation.selectedLocationIds.push(locationId)
        state.modalSelectLocation.selectedLocation.push({
          id: location.location_id,
          name: location.location_name,
          type: location.location_type,
        })
      }
    },
    toggleAllLocationsBySelectedLocationType: (state) => {
      state.modalSelectLocation.locationList.forEach((locationItem) => {
        if (!state.modalSelectLocation.selectedLocationIds.includes(locationItem.location_id)) {
          state.modalSelectLocation.selectedLocationIds.push(locationItem.location_id)
          state.modalSelectLocation.selectedLocation.push({
            id: locationItem.location_id,
            name: locationItem.location_name,
            type: locationItem.location_type,
          })
        }
      })
    },
  },
})

export const {
  resetState,
  setSelectedId,
  setShouldClearSearchKeyword,
  setParams,
  resetParams,
  setSelectedStatus,
  resetSelectedStatus,
  setSelectedCategoryL1,
  setSelectedCategoryL2,
  setSkuRegistrationDetailOnFetchSuccess,
  setSkuRegistrationDetail,
  updateIsRejectedOnGeneralData,
  updateIsRejectedOnHandlingData,
  updateIsRejectedOnGrammation,
  updateIsRejectedOnGrammationPrimary,
  updateIsRejectedOnGrammationSecondary,
  updateIsRejectedOnPricing,
  updateIsRejectedOnAdditionalData,
  updateIsRejectedOnSellingDetail,
  updateIsRejectedOnSkuAsset,
  updateIsRejectedOnExchangeProduct,
  updateIsRejectedOnAssortmentDetail,
  updateIsRejectedOnAssortmentPlanning,
  setCategories,
  setConstant,
  setSkuRegistrationList,
  setVendorList,
  setClusterList,
  setBrandList,
  setBrandDetail,
  setPpnList,
  setModalUpdateSku,
  resetModalUpdateSku,
  setSelectedDateRange,
  resetSelectedDateRange,
  setSelectedCreator,
  setCreatorList,
  setErrorGeneralForm,
  setErrorHandlingForm,
  setErrorGrammationForm,
  setErrorPricingForm,
  setGeneralDataForm,
  setHandlingDataForm,
  setGrammationForm,
  setGrammationPrimaryForm,
  setGrammationSecondaryForm,
  setPricingForm,
  setAdditionalForm,
  setSellingDetailForm,
  setErrorAdditionalForm,
  setErrorSellingDetailForm,
  setLocationList,
  setHubLevelList,
  setModalAdditionalDetails,
  resetModalAdditionalDetails,
  setModalApproveSku,
  resetIsRejectingModalApproveSku,
  setIsOpenModalRejectReason,
  resetModalApproveSku,
  setErrorSkuPortfolio,
  setModalSkuPortfolio,
  resetModalSkuPortfolio,
  setExchangeProductFormData,
  setAssortmentDetailForm,
  setAssortmentPlanningForm,
  setLabelList,
  setProductImageList,
  setErrorSkuAssetForm,
  setModalSkuMerchandising,
  resetModalSkuMerchandising,
  setSkuAssetForm,
  setUomList,
  setProductExchangeInfoDetail,
  setProductList,
  setBusinessTaggingList,
  setProductTypeList,
  setVendorDetail,
  setIsOpenModalCancelSkuRegis,
  setModalConfirmSubmit,
  setModalFailedDataCreation,
  setFailedDataCreationForm,
  setErrorFailedDataCreation,
  resetModalFailedDataCreation,
  setModalSelectLocation,
  resetModalSelectLocation,
  manageSelectedLocationOfModalSelectLocation,
  toggleAllLocationsBySelectedLocationType,
} = skuRegistration.actions
export default skuRegistration.reducer
