export const getNeededAttributeFromQueryObj = (queryObj) => {
  if (queryObj === null || typeof queryObj !== 'object') {
    return queryObj
  }

  let newQueryObj = {}
  const listKeys = Object.keys(queryObj)

  for (let key of listKeys) {
    if (queryObj[key] !== null) {
      if (typeof queryObj[key] === 'object') {
        const nestedResult = getNeededAttributeFromQueryObj(queryObj[key])
        if (Object.keys(nestedResult).length > 0) {
          newQueryObj[key] = nestedResult
        }
      } else {
        newQueryObj[key] = queryObj[key]
      }
    }
  }

  return newQueryObj
}
