import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  AdditionalSLAParamsType,
  AdditionalSLAResponseType,
  GetReasonsSLAResponseType,
} from 'features/Order/services/additionalSLA'
import {
  fetchAdditionalSLAList,
  fetchAllActiveHubs,
  fetchOrderLocations,
  fetchReasonsAdditionalSLA,
  submitAdditionalSLA,
  turnOffAdditionalSLA,
} from './thunk'

export type InitialStateType = {
  isLoading: boolean
  additionalSLAParams: AdditionalSLAParamsType
  additionalSLAList: AdditionalSLAResponseType[`data`]
  showPopUp: boolean
  showPopUpDeactivate: boolean
  reasonsList: GetReasonsSLAResponseType[`data`]
  deactivateSLAIds: number[]
  deactivateSLAIdSingle: number[] // yes, eventhough it will be only send one id, it's still an array
  locations: {
    id?: number
    name?: string
    location_id?: number
    location_name?: string
    location_type?: string
  }[]
  isExtending: boolean
  selectedData: Nullable<AdditionalSLAResponseType[`data`][number]>
}

const initialState: InitialStateType = {
  isLoading: false,
  additionalSLAParams: {},
  additionalSLAList: [],
  showPopUp: false,
  showPopUpDeactivate: false,
  reasonsList: [],
  deactivateSLAIds: [],
  deactivateSLAIdSingle: [],
  locations: [],
  isExtending: false,
  selectedData: null,
}

const additionalSLASlice = createSlice({
  name: 'additionalSLA',
  initialState,
  reducers: {
    setAdditionalSLAParams: (
      state,
      action: PayloadAction<InitialStateType['additionalSLAParams']>,
    ) => {
      state.additionalSLAParams = action.payload
    },
    setAdditionalSLAList: (state, action: PayloadAction<InitialStateType['additionalSLAList']>) => {
      state.additionalSLAList = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setShowPopUp: (state, action: PayloadAction<boolean>) => {
      state.showPopUp = action.payload
    },
    setReasonsList: (state, action: PayloadAction<GetReasonsSLAResponseType[`data`]>) => {
      state.reasonsList = action.payload
    },
    setShowPopUpDeactivate: (state, action: PayloadAction<boolean>) => {
      state.showPopUpDeactivate = action.payload
    },
    setDeactivateSLAIds: (state, action: PayloadAction<number[]>) => {
      state.deactivateSLAIds = action.payload
    },
    setDeactivateSLAIdSingle: (state, action: PayloadAction<number>) => {
      if (action.payload === 0) {
        state.deactivateSLAIdSingle = []
        return
      }
      state.deactivateSLAIdSingle = [action.payload]
    },
    setIsExtending: (state, action: PayloadAction<boolean>) => {
      state.isExtending = action.payload
    },
    setSelectedData: (state, action: PayloadAction<InitialStateType['selectedData']>) => {
      state.selectedData = action.payload
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<InitialStateType>) => {
    builder.addCase(fetchAllActiveHubs.fulfilled, (state, action) => {
      state.locations = action.payload.data
    })
    builder.addCase(fetchOrderLocations.fulfilled, (state, action) => {
      state.locations = action.payload.data.filter(
        (location) => location.location_type?.toLowerCase() === 'hub',
      )
    })
    builder.addCase(turnOffAdditionalSLA.fulfilled, (state) => {
      state.showPopUpDeactivate = false
    })
    builder.addCase(turnOffAdditionalSLA.rejected, (state) => {
      state.showPopUpDeactivate = false
    })
    builder.addCase(submitAdditionalSLA.fulfilled, (state) => {
      state.showPopUp = false
    })
    builder.addCase(submitAdditionalSLA.rejected, (state) => {
      state.showPopUp = false
    })
    builder.addCase(fetchAdditionalSLAList.fulfilled, (state, action) => {
      state.additionalSLAList = action.payload.data
    })
    builder.addCase(fetchReasonsAdditionalSLA.fulfilled, (state, action) => {
      state.reasonsList = action.payload.data
    })
  },
})

export const {
  setAdditionalSLAParams,
  setAdditionalSLAList,
  setLoading,
  setShowPopUp,
  setReasonsList,
  setShowPopUpDeactivate,
  setDeactivateSLAIdSingle,
  setDeactivateSLAIds,
  setIsExtending,
  setSelectedData,
} = additionalSLASlice.actions
export default additionalSLASlice.reducer
