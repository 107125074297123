/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  createAxiosInstance,
  AxiosError,
  AxiosResponse,
  withFileService,
  generateDeviceId,
} from '@astronautsid/wpe-utils'

import { axiosErrorLoggerInterceptor } from 'config/axiosLogger'
import { getTokenCookies } from 'config/cookies'
import { getRequestXDevice } from 'utils/helpers/userAgent'

const {
  REACT_APP_BASE_URL_API_FILE_SERVICE,
  REACT_APP_NAME,
  REACT_APP_SYSTEM_MAINTENANCE_URL,
  REACT_APP_MAINTENANCE,
} = process.env

const handleRejected = (error: AxiosError) => {
  if (error?.request.status === 302 || REACT_APP_MAINTENANCE === 'true') {
    window.location.assign(`${REACT_APP_SYSTEM_MAINTENANCE_URL}?callback=${window.location.href}`)
  }
  axiosErrorLoggerInterceptor(error)

  return Promise.reject(error)
}

const axiosInstanceFileService = createAxiosInstance(
  {
    baseURL: REACT_APP_BASE_URL_API_FILE_SERVICE,
    headers: {
      'X-Service-Name': REACT_APP_NAME,
    },
  },
  {
    interceptorRequest: async (config) => {
      const token = getTokenCookies()
      const headers = config.headers as Exclude<typeof config.headers, undefined>
      /* This way requests are allowed to override interceptor */
      headers.Authorization =
        headers && headers.Authorization !== undefined ? headers.Authorization : `Bearer ${token}`
      config.headers = headers
      ;(config.headers as Exclude<typeof config.headers, undefined>)['x-device'] =
        getRequestXDevice()
      ;(config.headers as Exclude<typeof config.headers, undefined>)['x-device-id'] =
        await generateDeviceId()
      return config
    },
    interceptorResponse: (response: AxiosResponse) => {
      if (REACT_APP_MAINTENANCE === 'true') {
        window.location.assign(
          `${REACT_APP_SYSTEM_MAINTENANCE_URL}?callback=${window.location.href}`,
        )
      }
      return response
    },
    interceptorResponseReject: null,
    interceptorRequestReject: handleRejected,
  },
)

export const {
  ServiceCustomerFileRules,
  ServiceCustomerFileUpload,
  ServiceInternalFileRules,
  ServiceInternalFileUpload,
} = withFileService(axiosInstanceFileService)

export default axiosInstanceFileService
