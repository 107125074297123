import { Get, Put, Post } from 'config/apiServiceConfig'
import AxiosInstance from 'config/apiServiceApiGateway'

export type FetchMPStaffQueryType =
  | Partial<{
      pageIndex: number
      pageSize: string
      locationType: string
      name: string
      locationId: number
      role: string
      staffId: string
    }>
  | undefined

export type FetchStaffRequestType = {
  location_ids?: number[]
  location_types?: string[]
  module?: {
    bank_account: boolean
    department_location: boolean
    staff_attributes: boolean
    staff_vendor: boolean
    termination_status: boolean
    vehicle: boolean
  }
  name?: string
  page_index?: number
  page_size?: number
  phone_number?: string
  roles?: string[]
  staff_ids?: string[]
  status_active?: string
}

export type MPStaffType = {
  location_id: number
  join_date: string
  created_by: string
  location_name: string
  status: string
  role: string
  id: number
  bank_account_data: {
    id: number
    account_number: string
    account_name: string
    staff_id: number
    bank_name: string
  }
  is_verified: boolean
  is_blacklist: boolean
  staff_termination_status_data: {
    termination_status_code: number
    approval_id: number
    termination_status: string
  }
  staff_attributes_data: {
    staff_id: number
    flexible_checkout: boolean
    working_location_id: number
    job_id: string
    hired_role: string
  }
  user_id: number
  staff_location_department_data: {
    staff_id: number
    location_type: string
    department: string
  }
  full_name: string
  staff_vendor_data: {
    staff_id: number
    vendor_id: number
    vendor_name: string
  }
  vehicle_type: string
  termination_date: string
  employment_type: string
  staff_role: {
    authority_role: string
    role_name_fmt: string
    active: boolean
  }[]
  is_active: boolean
  updated_by: string
  staff_app_version: string
  plate_number: string
  employee_detail: number
  vehicle_sub_type: string
  card_number: string
  termination_reason: string
  phone_number: string
  location_type: string
  tracker_id: string
}

export type MPStaffPaginationType = {
  direction: string
  number_of_elements: number
  page_index: number
  page_size: number
  sort: string
  total_elements: number
  total_pages: number
}

export type FetchStaffResponseType = {
  data: MPStaffType[]
  pagination: MPStaffPaginationType
}

export type GetResignationResonsTypeResponseType = {
  data: ResignationResonsType
}

export type ResignationResonsType = {
  [key: string]: {
    reasons: string[]
  }
}

export type PutResignationPayloadType = {
  approval_ids: number[]
  location_id: number
  requester_roles: string[]
  termination_date?: string
  termination_reason?: string
  join_date?: string
  updated_by: string
  is_flexible_checkout: boolean
}

export type FetchMPStaffVehicleTypeResponseType = {
  data: MPStaffVehicleType
}

export type MPStaffVehicleType = {
  vehicle_sub_type: {
    key: string
    value: string
  }[]
  vehicle_type: {
    key: string
    value: string
  }[]
}

export type FetchMPStaffVendorListResponseType = {
  data: {
    data: {
      data: MPStaffVendorListType[]
    }
  }
}

export type MPStaffVendorListType = {
  id: number
  name: string
  status: string
}

export type FetchMPStaffDepartementListResponseType = {
  data: MPStaffDepartementListType
}

export type MPStaffDepartementListType = {
  [key: string]: {
    names: string[]
  }
}

export type FetchLocationListRequestType =
  | Partial<{
      name: string
      type: string
    }>
  | undefined

export type FetchLocationListResponseType = {
  content: MPStaffLocationType[]
}

export type MPStaffLocationType = {
  location_active: boolean
  location_address: string
  location_detail: string
  location_flag: string
  location_id: number
  location_name: string
  location_type: string
  supervisor_names: string[]
}

export type MPStaffRequestPayloadType = {
  staff_location_id: number
  staff_card_number: string
  staff_plate_number: string
  staff_phone_number: string
  role: string[]
  staff_full_name: string
  employment_type: string
  location_type: string
  vehicle_type: string
  vehicle_sub_type: string | null
  tracker_id: string
  staff_vendor_id?: number | null
  bank_name: string
  account_name: string
  account_number: string
  join_date: string
  employee_detail: number
  department: string
  is_flexible_checkout: boolean
  job_id: string
  user_name: string
  termination_date?: string
  termination_reason?: string
  is_blacklist?: boolean
  approval_ids?: number[]
  staff_id?: number
}

export type PutMPStaffRequestType = {
  id: number
  data: MPStaffRequestPayloadType
}

export type FetchJobIDsParamsType = {
  location_id: number
  employment_type: string
  role: string
}

export type JobIDsType = {
  id: number
  job_id: string
}

export const getMPStaffListAPI = (data: FetchStaffRequestType) =>
  AxiosInstance.Post<FetchStaffResponseType, true>({
    url: '/staff-management/internal/:version/staff/get-with-pagination',
    version: 'v1',
    data,
  })

export const getMPStaffDetailAPI = (id: number) =>
  AxiosInstance.Get<{ data: MPStaffType }, true>({
    url: '/staff-management/internal/:version/staff/:id',
    version: 'v1',
    urlParams: { id },
  })

export const putMPStaffStatusAPI = (id: number, is_available: boolean) =>
  Put({ url: `/api/driver-admin/${id}/available`, data: { is_available } })

export const getTerminationReasonsAPI = () =>
  Get<GetResignationResonsTypeResponseType>({ url: '/api/staff-management/admin/all-reason-list' })

export const putTerminationMPStaffAPI = (id: number, data: PutResignationPayloadType) =>
  AxiosInstance.Put({ url: `/staff-management/internal/v1/staff/${id}/resignation`, data })

export const putRejoinMPStaffAPI = (id: number, data: PutResignationPayloadType) =>
  AxiosInstance.Put({ url: `/staff-management/internal/v1/staff/${id}/rejoin`, data })

export const getVehicleTypeAPI = () =>
  Get<FetchMPStaffVehicleTypeResponseType>({
    url: '/api/driver-admin/vehicle-type',
  })

export const getEmploymentTypeAPI = () =>
  AxiosInstance.Get<{ data: string[] }, true>({
    url: '/staff-management/internal/:version/staff/employment-types',
    version: 'v1',
  })

export const getStaffVendorListAPI = () =>
  Get<FetchMPStaffVendorListResponseType>({
    url: '/api/staff-management/staff-vendors/list',
  })

export const getDepartementListPI = () =>
  Get<FetchMPStaffDepartementListResponseType>({
    url: '/api/staff-management/admin/all-department-list',
  })

export const getLocationListAPI = (params: FetchLocationListRequestType) =>
  Get<FetchLocationListResponseType>({
    url: '/api/location',
    params,
  })

export const getStaffRoleListAPI = (params: { locationType: string }) =>
  Get<{ data: string[] }>({
    url: '/api/driver-admin/role',
    params,
  })

export const createMPStaffAPI = (data: MPStaffRequestPayloadType) =>
  AxiosInstance.Post({
    url: `/staff-management/internal/:version/staff`,
    data,
    version: 'v1',
  })

export const putMPStaffAPI = ({ id, data }: PutMPStaffRequestType) =>
  AxiosInstance.Put({
    url: `/staff-management/internal/:version/staff/${id}`,
    data,
    version: 'v1',
    urlParams: { id },
  })

export const exportMPStaffAPI = (locationType: string) =>
  Get<{ data: { file_url: string } }>({
    url: '/api/driver-admin/export',
    params: { locationType },
  })

export const postUpdateActiveRoleAPI = (id: number, role: string) =>
  Post({
    url: `/api/staff-management/staff/${id}/role`,
    urlParams: { id },
    data: { role },
  })

export const getJobIDsAPI = (params: FetchJobIDsParamsType) =>
  AxiosInstance.Get<{ data: JobIDsType[] }, true>({
    url: '/staff-management/internal/:version/hiring-funnel/job-id/list',
    version: 'v1',
    params,
  })
