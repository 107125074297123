import axiosInstanceApiGateway from 'config/apiServiceApiGateway'
import { Get as GetOLD } from 'config/apiServiceConfig'
import { OrderLocationResponseType } from './allOrder'

const { Get, Put, Post } = axiosInstanceApiGateway

export type AdditionalSLAResponseType = {
  data: AdditionalSLADataType[]
}

export type AdditionalSLAParamsType = Partial<{
  active: boolean
  reason_id: number
  location_id: string
  page_index: number
  page_size: number
}>

export type AdditionalSLADataType = {
  id: number
  hub: {
    location_id: string
    name: string
    info: string
  }
  additional_sla: {
    min_additional_duration_msec: number
    max_additional_duration_msec: number
  }
  reason: {
    id: string
    reason: string
  }
  timestamps: {
    created_at: number
    start_at: number
    ended_at: number
    duration_msec: number
  }
  user: {
    created_by: string
    created_by_role: string
    created_by_id: string
  }
  status: {
    name: string
    text_color: string
    bg_color: string
  }
}

export type PutNewAdditionalSLAType = {
  hub_ids: number[]
  reason_id: number
  duration: number
}

export type PutExtendAdditionalSLAType = {
  duration: number
}

export type PutDeactivateAdditionalSLAType = {
  ids: number[]
}

export type GetReasonsSLAResponseType = {
  data: {
    id: number
    description: string
    additional_sla: number
  }[]
}

export type GetActiveHubSLAResponseType = {
  data: {
    id: number
    name: string
  }[]
}

export const getAdditionalSLA = (params: AdditionalSLAParamsType) =>
  Get<AdditionalSLAResponseType, true>({
    url: '/fulfillment/internal/v1/additional-sla/external/configurations',
    params,
  })

export const getReasonsSLA = () =>
  Get<GetReasonsSLAResponseType, true>({
    url: '/fulfillment/internal/v1/additional-sla/external/configurations/reasons',
  })

export const getActiveHubsSLA = () =>
  Get<GetActiveHubSLAResponseType>({
    url: '/fulfillment/internal/v1/additional-sla/external/configurations/active-hubs',
  })
export const getOrderLocations = () =>
  GetOLD<OrderLocationResponseType>({ url: `/api/locations/order` })

export const putDeactivateAdditionalSLA = (params: PutDeactivateAdditionalSLAType) =>
  Put({
    url: '/fulfillment/internal/v1/additional-sla/external/configurations/deactivate',
    data: params,
  })

export const postAdditionalSLA = (payload: {
  hub_ids: number[]
  duration: number
  reason_id: number
}) =>
  Post({ url: '/fulfillment/internal/v1/additional-sla/external/configurations', data: payload })

export const postExtendSLA = (payload: { duration: number; id: number }) => {
  const { id, ...rest } = payload
  return Put({
    url: `/fulfillment/internal/v1/additional-sla/external/configurations/${id}/extend`,
    data: rest,
  })
}
