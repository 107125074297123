import { Box } from '@astro-ui/components'
import { themeOptions } from '@astro-ui/theme'
import React from 'react'

export type AlertPropsType = {
  backgroundColor?: string
  lineColor?: string
  children: React.ReactNode
}

const Alert = ({ backgroundColor, lineColor, children }: AlertPropsType) => (
  <Box
    width="100%"
    marginTop="16px"
    marginBottom="16px"
    overflow="hidden"
    backgroundColor={backgroundColor || themeOptions.colors?.lightOrange}
    borderRadius="8px"
    display="flex"
    flexWrap="wrap"
  >
    <Box backgroundColor={lineColor || themeOptions.colors?.bgOrange} width="4px" />
    <Box marginLeft="12px" marginTop="16px" marginBottom="16px">
      {children}
    </Box>
  </Box>
)

export default Alert
