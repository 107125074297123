import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getAdditionalSLA,
  getReasonsSLA,
  AdditionalSLAParamsType,
  getActiveHubsSLA,
  getOrderLocations,
  putDeactivateAdditionalSLA,
  postAdditionalSLA,
  postExtendSLA,
} from 'features/Order/services/additionalSLA'
import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess } from 'utils/toast'

export const fetchAdditionalSLAList = createAsyncThunk(
  'additionalSLA/fetchAdditionalSLAList',
  async (params: AdditionalSLAParamsType, { rejectWithValue }) => {
    try {
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(([_, value]) => value !== undefined), // Filter out undefined values
      )

      const response = await getAdditionalSLA(filteredParams)
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchReasonsAdditionalSLA = createAsyncThunk(
  'additionalSLA/fetchReasonsAdditionalSLA',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getReasonsSLA()
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchAllActiveHubs = createAsyncThunk(
  'additionalSLA/fetchAllActiveHubs',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getActiveHubsSLA()
      return response.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchOrderLocations = createAsyncThunk(
  'additionalSLA/fetchOrderLocations',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getOrderLocations()
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const turnOffAdditionalSLA = createAsyncThunk(
  'additionalSLA/turnOffAdditionalSLA',
  async (type: 'single' | 'multiple', { rejectWithValue, getState }) => {
    const {
      additionalSLA: { deactivateSLAIdSingle, deactivateSLAIds },
    } = getState() as StoreStateType
    try {
      const payload = { ids: type === 'multiple' ? deactivateSLAIds : deactivateSLAIdSingle }
      const response = await putDeactivateAdditionalSLA(payload)
      toastSuccess('Additional SLA berhasil dinonaktifkan')
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const submitAdditionalSLA = createAsyncThunk(
  'additionalSLA/submitAdditionalSLA',
  async (
    payload: { hub_ids: number[]; duration: number; reason_id: number },
    { rejectWithValue },
  ) => {
    try {
      const response = await postAdditionalSLA(payload)
      toastSuccess('Additional SLA berhasil ditambahkan')
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const submitExtendSLA = createAsyncThunk(
  'additionalSLA/submitExtendSLA',
  async (payload: { duration: number; id: number }, { rejectWithValue }) => {
    try {
      const response = await postExtendSLA(payload)
      toastSuccess('Additional SLA berhasil diExtend')
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
