import { memo, useState, useRef, useContext, useMemo } from 'react'
import styled from 'styled-components'

import { Box, Image, Select, Typography } from '@astro-ui/components'
import { SystemIcon, TextBody, Button, InputDropDown, DeviceWrapper } from 'components'
import {
  purchaseOrder,
  order,
  orderPicking,
  orderPacking,
  orderWaitingDriver,
  orderOnDelivery,
  orderBackToHub,
  orderNotAvailable,
  orderSuper,
  orderPickingSuper,
  orderPackingSuper,
  orderWaitingDriverSuper,
  orderOnDeliverySuper,
  orderBackToHubSuper,
  orderUpcoming,
  createOrder,
  driverMonitoring,
  hcu,
  poolingMap,
  orderWaitingConfirmation,
  orderReadyForDelivery,
} from 'config/routes'
import { useHistory, useLocation } from 'react-router-dom'
import handleClickOutSide from 'customHooks/outSideClick'
import logo from 'assets/images/logo.png'

import { setSidebarOpen } from 'redux/app'
import { handleLogout } from 'features/Auth/redux/Auth'

import storeContext from 'storeContext'
import { setSelectedLocation } from 'storeContext/actions/packer'
import { transformQuerytoObj, transformObjtoQuery } from 'helpers'
import { setPopupConfirmReset } from 'features/Order/redux/CreateOrder'
import { useAppSelector, useAppDispatch, shallowEqual } from 'store'
import {
  fetchGetLocationDetail,
  setSelectedDriverLocation,
} from 'features/Order/redux/DriverMonitoring'
import { fetchLocationDetail, setLocationSelected } from 'features/Order/redux/PoolingMap'
import { OrderRouterDetail } from 'features/Order/router'
import SelectLocation from 'features/Order/pages/AllOrderV2/SelectLocation/SelectLocation'

const allowLocation = [purchaseOrder]
const checkPathToOrder = [
  order,
  orderPicking,
  orderPacking,
  orderWaitingDriver,
  orderOnDelivery,
  orderBackToHub,
  orderNotAvailable,
  orderSuper,
  orderPickingSuper,
  orderPackingSuper,
  orderWaitingDriverSuper,
  orderOnDeliverySuper,
  orderBackToHubSuper,
  orderUpcoming,
  orderWaitingConfirmation,
  createOrder,
  hcu,
  orderReadyForDelivery,
]
const selector = ({
  app: {
    layout: { isSidebarOpen },
  },
  auth,
  createOrder,
}) => ({
  isSidebarOpen,
  activeStep: createOrder.activeStep,
  full_name: auth?.userData?.full_name,
})
function Navbar({ className }) {
  const {
    state: { locations, selectedLocation },
    dispatch,
  } = useContext(storeContext.PackerContext)

  const history = useHistory()
  const dispatchRedux = useAppDispatch()
  const { isSidebarOpen, activeStep, full_name } = useAppSelector(selector, shallowEqual)
  const driverMonitoringState = useAppSelector(
    (state) => ({
      locations: state.driverMonitoring.listLocation,
      selectedLocation: state.driverMonitoring.selectedLocation,
    }),
    shallowEqual,
  )
  const orderPoolingMapState = useAppSelector(
    ({ poolingMap }) => ({
      locations: poolingMap.listLocation,
      selectedLocation: poolingMap.locationSelected,
    }),
    shallowEqual,
  )

  const refDropDown = useRef(null)
  const [isShowDropDown, setIsShowDropDown] = useState(false)

  const location = useLocation()

  const allowFilterLocation = useMemo(() => {
    return allowLocation.includes(location.pathname)
  }, [location.pathname])

  const allowFilterOrderLocation = useMemo(() => {
    return checkPathToOrder.includes(location.pathname)
  }, [location.pathname])

  const handleClickDropDown = () => {
    setIsShowDropDown(true)
  }

  handleClickOutSide(
    refDropDown,
    () => {
      setIsShowDropDown(false)
    },
    isShowDropDown,
  )

  const onLogout = () => {
    dispatchRedux(handleLogout())
  }

  const handleClickExpand = (e) => {
    e.stopPropagation()
    dispatchRedux(setSidebarOpen(!isSidebarOpen))
  }

  const handleClickOption = (locationData) => {
    if (!locationData) return
    if (location.pathname.includes(createOrder)) {
      if (activeStep > 1) {
        dispatchRedux(setPopupConfirmReset(true))
      }
    }
    dispatch(setSelectedLocation(locationData))
    const queryObj = transformQuerytoObj(location.search)
    queryObj.locationId = locationData.location_id
    const query = transformObjtoQuery(queryObj)
    history.replace(query)
  }

  const handleSelectLocationDriver = async (loc) => {
    if (loc?.location_id) {
      const response = await dispatchRedux(fetchGetLocationDetail(loc.location_id)).unwrap()
      dispatchRedux(setSelectedDriverLocation(response))
    } else {
      dispatchRedux(setSelectedDriverLocation(null))
    }
  }

  const handleSelectLocationOrderPooling = async (loc) => {
    if (loc?.location_id) {
      const response = await dispatchRedux(fetchLocationDetail(loc.location_id)).unwrap()
      dispatchRedux(setLocationSelected(response))
    } else {
      dispatchRedux(setLocationSelected(null))
    }
  }

  return (
    <Container
      className={className}
      p={{
        mobile: '16px',
        desktop: '16px 30px',
      }}
    >
      <WrapperLogo>
        {true &&
          (isSidebarOpen ? (
            <Button variant="wrapper" onClick={handleClickExpand}>
              <IconCloseWrapper>
                <SystemIcon iconName="plus" fontSize="headerBigger" colorIcon="text" />
              </IconCloseWrapper>
            </Button>
          ) : (
            <Button variant="wrapper" onClick={handleClickExpand}>
              <SystemIcon iconName="hamburger" fontSize="headerBigger" colorIcon="text" />
            </Button>
          ))}
        <WrapperLogoAndVersion>
          <Logo src={logo} alt="logo" height={24} />
          <TextBody size="toast" weight="lightest" color="textV2">
            v{process.env.REACT_APP_VERSION}
          </TextBody>
        </WrapperLogoAndVersion>
      </WrapperLogo>
      <RightWrapper>
        {allowFilterLocation && (
          <>
            <StyledDropDown iconName="store" />
            <Devider />
          </>
        )}
        {allowFilterOrderLocation && (
          <>
            <Select
              className="ml-16"
              value={selectedLocation?.name ?? ''}
              options={locations}
              onChange={handleClickOption}
              placeholder="Pilih Hub"
              withSearch
              required
            />
            <Devider />
          </>
        )}
        {location.pathname === driverMonitoring && (
          <>
            <Select
              className="ml-16"
              value={driverMonitoringState.selectedLocation}
              options={driverMonitoringState.locations}
              onChange={handleSelectLocationDriver}
              placeholder="Pilih Hub"
              displayKey="location_name"
              withSearch
            />
            <Devider />
          </>
        )}
        {location.pathname === poolingMap && (
          <>
            <Select
              className="ml-16"
              value={orderPoolingMapState.selectedLocation}
              options={orderPoolingMapState.locations}
              onChange={handleSelectLocationOrderPooling}
              placeholder="Pilih Hub"
              displayKey="location_name"
              withSearch
            />
            <Devider />
          </>
        )}
        {location.pathname === OrderRouterDetail.AllOrderV2Router.path && <SelectLocation />}
        <Notif>
          <SystemIcon iconName="notification" fontSize="header" />
          <Bandage />
        </Notif>
        <Account>
          <SystemIcon iconName="person" colorIcon="white" />
        </Account>
        <DeviceWrapper show={['DESKTOP']} ml="8px">
          <Typography variant="h4">{full_name}</Typography>
        </DeviceWrapper>
        <span onClick={handleClickDropDown}>
          <SystemIcon iconName="dropdown" className="ml-5 pointer" />
        </span>
        {isShowDropDown && (
          <Actions ref={refDropDown}>
            <DeviceWrapper
              show={['MOBILE', 'TABLET']}
              p="0 12px 8px"
              borderBottom="1px solid lightgray"
              textAlign="center"
            >
              <Typography>{full_name}</Typography>
            </DeviceWrapper>
            <Button variant="wrapper" className="w-100" onClick={onLogout}>
              <Act>
                <SystemIcon iconName="logout" colorIcon="red" />
                <Typography color="red">Keluar</Typography>
              </Act>
            </Button>
          </Actions>
        )}
      </RightWrapper>
    </Container>
  )
}

Navbar.defaultProps = {
  className: '',
}

export default memo(Navbar)

const WrapperLogoAndVersion = styled.div`
  display: flex;
  flex-direction: column;
`

const Account = styled.div`
  background-color: ${({ theme: { colors } }) => colors.main};
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: grid;
  place-items: center;
  margin-left: 16px;
  margin-right: 0px;
`

const Bandage = styled.div`
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
`

const Notif = styled.div`
  position: relative;
`

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

const Container = styled(Box)`
  background-color: ${({ theme: { colors } }) => colors.white};
  /* padding: 16px 30px; */
  width: 100%;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  z-index: 2;
`

const Actions = styled.div`
  border-radius: 6px;
  border: 1px solid #e4eaf3;
  box-shadow: 0px 2px 16px rgba(202, 211, 225, 0.4);
  position: absolute;
  right: 0;
  background-color: ${({ theme: { colors } }) => colors.white};
  top: 45px;
  width: 350px;
  padding: 10px;
`

const Act = styled.div`
  padding: 10px;
  display: flex;
  gap: 10px;
  cursor: pointer;
  align-items: center;
  p {
    white-space: nowrap;
  }

  :hover {
    background-color: aliceblue;
  }
`

const Logo = styled(Image)`
  /* height: 16px; */
`

const WrapperLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`
const IconCloseWrapper = styled.div`
  transform: rotate(45deg);
  height: fit-content;
  width: fit-content;
`

const StyledDropDown = styled(InputDropDown)`
  width: 150px;
  input {
    padding: 6px 0;
  }
`

const Devider = styled.div`
  border: 1px solid #d6dfeb;
  margin: 0px 15px;
  width: 1px;
  height: 25px;
`
