import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getAllFiltersV2,
  getAllOrderV2API,
  getOrderLocations,
} from 'features/Order/services/allOrder'
import { callErrorMsg } from 'helpers/errorMsg'
import { getExternalDriverRates } from 'utils/apiList/externalDriver'

export type CommonNameValueObjectType = {
  name: string
  value: string
  info: string
  icon?: string
  bg_color?: string
  text_color?: string
  border_color?: string
}

export type OrderV2ResponseType = {
  orders: {
    order: {
      parent_id: number
      order_id: number
      invoice: string
      sibling_orders: {
        order_id: number
        order_type: CommonNameValueObjectType
        order_status: CommonNameValueObjectType
      }[]
      order_type: CommonNameValueObjectType
      order_status: CommonNameValueObjectType
      external_order: {
        ext_order_id: string
        ext_secondary_order_id: string
        provider: string
      }
      customer: {
        name: string
        segment_icon: 'NEW_USER' | 'REGULAR_USER' | 'VIP_USER' | 'VIP_PRIO_USER'
      }
    }
    fulfillment: {
      origin: {
        type: string
        id: number
        name: string
      }
      quantity: {
        total_order_qty: number
        total_fulfilled_qty: number
      }
      tags: CommonNameValueObjectType &
        {
          type: string
          text: string
        }[]
    }
    delivery: {
      destination: {
        type: string
        id: number
        name: string
        address: string
      }
      reciever: {
        name: string
        contact_type: string
        contact: string
      }
      courier: {
        type: string
        name: string
        contact_type: string
        contact: string
        plate_number: string
        external_courier: {
          provider: string
          booking_id: string
        }
      }
      pooling: {
        pool_id: number
        pool_number: string
        pooled_orders: {
          order_id: number
          status: string
        }[]
      }
    }
    time: {
      timing: CommonNameValueObjectType & { subtitle: string }
      timestamp: {
        created_at: number
        payment_at: number
        start_at: number
        arrived_at: number
        ended_at: number
        min_arrived_at: number
        max_arrived_at: number
      }
      additional_sla: {
        additional_min_arrive_ms: number
        additional_max_arrive_ms: number
        info: string
      }
    }
  }[]
}

export type OrderV2FilterType = {
  order_status: CommonNameValueObjectType[]
  order_types: CommonNameValueObjectType[]
  slas: CommonNameValueObjectType[]
  locations: CommonNameValueObjectType[]
}

export const fetchAllOrderV2 = createAsyncThunk(
  `allOrderV2/fetchAllOrderV2`,
  async (payload: string) => {
    try {
      const response = await getAllOrderV2API(payload)
      return response.data
    } catch (err) {
      return callErrorMsg(err)
    }
  },
)
export const fetchExternalDriverRatesV2 = createAsyncThunk(
  'externalDriver/fetchExternalDriverRates',
  async (payload: { orderID: number; locationID?: number | string }) => {
    const params = { locationId: payload.locationID }
    try {
      const response = await getExternalDriverRates(payload, params)
      return response.data.data
    } catch (error) {
      return callErrorMsg(error)
    }
  },
)
export const fetchAllOrderV2Filter = createAsyncThunk(
  'allOrderV2/fetchAllOrderV2Filter',
  async () => {
    try {
      const response = await getAllFiltersV2()
      return response.data
    } catch (err) {
      return callErrorMsg(err)
    }
  },
)

export const getOrderV2Locations = createAsyncThunk('allOrderV2/getOrderV2Locations', async () => {
  try {
    const response = await getOrderLocations()
    return response.data
  } catch (err) {
    return callErrorMsg(err)
  }
})
