import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { withLoadingReducer } from 'utils/reducerHandler'
import {
  fetchAllOrderV2,
  fetchAllOrderV2Filter,
  getOrderV2Locations,
  OrderV2FilterType,
  OrderV2ResponseType,
} from './thunk'

export type InitialStateType = {
  isLoading: boolean
  dialogState:
    | 'RETURN_VERIFICATION_DIALOG'
    | 'ON_HOLD_DIALOG'
    | 'SKIP_VERIFICATION_PACKAGE_DIALOG'
    | 'SKIP_VERIFICATION_LOCATION_DIALOG'
    | 'COMPLETE_ORDER_DIALOG'
    | 'REDELIVERY_DIALOG'
    | 'EDIT_ADDRESS_DIALOG'
    | 'CANCEL_ORDER_DIALOG'
    | 'SKIP_VERIFICATION_DROP_OFF'
    | 'COMPLETE_ORDER_KITCHEN'
    | 'CLOSED'
    | 'POOLING_DIALOG'
  popUpSkipDropOff: 'SKIP_VERIFICATION_DROP_OFF' | 'CLOSED'
  orderList: OrderV2ResponseType['orders']
  selectedOrder: OrderV2ResponseType['orders'][number] | ObjectNullType
  filters: OrderV2FilterType
  listLocation: {
    location_id: number
    location_name: string
    location_type: string
  }[]
  locationSelected: Nullable<{
    location_id: number
    location_name: string
    location_type: string
  }>
  selectedPoolOrder: OrderV2ResponseType['orders']
  mainPoolOrder: OrderV2ResponseType['orders']
}

const initialState: InitialStateType = {
  isLoading: false,
  dialogState: 'CLOSED',
  popUpSkipDropOff: 'CLOSED',
  orderList: [],
  selectedOrder: {},
  filters: {
    order_status: [],
    order_types: [],
    slas: [],
    locations: [],
  },
  listLocation: [],
  locationSelected: null,
  selectedPoolOrder: [],
  mainPoolOrder: [],
}

const allOrderV2Slice = createSlice({
  name: 'allOrderV2',
  initialState,
  reducers: {
    setResetOrder: () => ({
      ...initialState,
      orderList: [],
    }),
    setDialogState: (state, action: PayloadAction<InitialStateType['dialogState']>) => {
      state.dialogState = action.payload
    },
    setSelectedOrderData: (state, action: PayloadAction<InitialStateType['selectedOrder']>) => {
      state.selectedOrder = action.payload
    },
    setPopUpSkipDropOff: (state, action: PayloadAction<InitialStateType['popUpSkipDropOff']>) => {
      state.popUpSkipDropOff = action.payload
    },
    setPopUpDone: (state, action: PayloadAction<InitialStateType['popUpSkipDropOff']>) => {
      state.popUpSkipDropOff = action.payload
    },
    setLocationSelected: (state, action: PayloadAction<InitialStateType['locationSelected']>) => {
      state.locationSelected = action.payload
    },
    // unused prone for deletion
    setPoolOrder: (state, action: PayloadAction<OrderV2ResponseType['orders']>) => {
      if (action.payload.length === 0) {
        state.selectedPoolOrder = []
        return
      }

      const newOrders = action.payload.filter(
        (newOrder) =>
          !state.selectedPoolOrder.some(
            (existingOrder) => existingOrder.order.order_id === newOrder.order.order_id,
          ),
      )
      state.selectedPoolOrder.push(...newOrders)
    },
    setPoolOrder1By1: (state, action: PayloadAction<OrderV2ResponseType['orders'][number]>) => {
      const prevOrder = state.selectedPoolOrder
      const isExist = prevOrder.find(
        (order) => order.order.order_id === action.payload.order.order_id,
      )

      if (isExist) {
        state.selectedPoolOrder = prevOrder.filter(
          (order) => order.order.order_id !== action.payload.order.order_id,
        )
      } else {
        state.selectedPoolOrder.push(action.payload)
      }
    },
  },
  extraReducers: withLoadingReducer(
    (builder: ActionReducerMapBuilder<InitialStateType>) => {
      builder.addCase(fetchAllOrderV2.fulfilled, (state, action) => {
        if (action.payload && action.payload.data.orders) {
          const ordersResponse = action.payload.data.orders
          state.orderList = ordersResponse
        }
      })
      builder.addCase(fetchAllOrderV2Filter.fulfilled, (state, action) => {
        if (action.payload) {
          state.filters = action.payload as unknown as OrderV2FilterType
        }
      })
      builder.addCase(getOrderV2Locations.fulfilled, (state, action) => {
        if (action.payload && action.payload.data) {
          state.listLocation = action.payload.data
        }
      })
    },
    [fetchAllOrderV2, fetchAllOrderV2Filter, getOrderV2Locations],
  ),
})
export const {
  setResetOrder,
  setDialogState,
  setSelectedOrderData,
  setPopUpSkipDropOff,
  setPopUpDone,
  setLocationSelected,
  setPoolOrder,
  setPoolOrder1By1,
} = allOrderV2Slice.actions
export default allOrderV2Slice.reducer
