import { setLocationSelected, type InitialStateType } from 'features/Order/redux/AllOrder_V2'
import { shallowEqual, useAppDispatch, useAppSelector } from 'store'

const useSelectLocation = () => {
  const { listLocation, locationSelected } = useAppSelector(
    (state) => state.allOrder_V2,
    shallowEqual,
  )
  const dispatch = useAppDispatch()

  const handleSelectLocation = (location: InitialStateType['listLocation'][number]) => {
    dispatch(setLocationSelected(location.location_id ? location : null))
  }
  return { listLocation, handleSelectLocation, locationSelected }
}

export default useSelectLocation
