import { lazy } from 'react'

import { roles } from 'constant/roles'
import generateRouterDetail from 'utils/helpers/generateRouterDetail'
import { MPHiringFunnelRBAC, PrioritizationSettingRBAC } from './rbac'

const MPStaff = lazy(() => import('../pages/MPStaff'))
const MPHiringFunnel = lazy(() => import('../pages/MPHiringFunnel/HiringFunnel'))
const PrioritizationSetting = lazy(() => import('../pages/MPHiringFunnel/PrioritizationSetting'))
const ManPowerBulkUpload = lazy(() => import('features/Enterprise/HRIS/pages/ManPowerBulkUpload'))

export const whManager = [
  roles.AUTH_WH_MANAGER,
  roles.AUTH_WH_INBOUND_SPV,
  roles.AUTH_WH_OUTBOUND_SPV,
  roles.AUTH_WH_INV_SPV,
  roles.AUTH_HO_IT,
]
export const hubManager = [roles.AUTH_HUB_SPV, roles.AUTH_SPV_HUB, roles.AUTH_HO_IT]
export const hrLead = [
  roles.AUTH_PRODUCT_MANAGER,
  roles.AUTH_HO_HR,
  roles.AUTH_HR_LEAD,
  roles.AUTH_HO_IT,
]
export const rejoinAction = [roles.AUTH_HO_HR, roles.AUTH_HR_LEAD, roles.AUTH_HO_IT]
export const captainAndDriver = [
  roles.AUTH_CAPTAIN,
  roles.AUTH_CS_CAPTAIN,
  roles.AUTH_DRIVER_COORDINATOR,
  roles.AUTH_PRODUCT_MANAGER,
  roles.AUTH_QUALITY_ENGINEERING,
  roles.AUTH_HO_IT,
]
export const exportStaff = [...hrLead, roles.AUTH_SPV_HUB, roles.AUTH_HUB_SPV]

export const MPStaffRouter: RouterFeatureInterface<'MPStaffRouter', '/dashboard/mp-staff'> = {
  name: 'MPStaffRouter',
  Component: MPStaff,
  path: '/dashboard/mp-staff',
  routeType: 'PRIVATE',
  allowedRoles: [
    roles.AUTH_PACKER,
    roles.AUTH_CUSTOMER_SERVICE,
    roles.AUTH_HO_HR,
    roles.AUTH_HR_LEAD,
    ...captainAndDriver,
    ...whManager,
    ...hubManager,
  ],
  isDashboard: true,
}

export const MPHiringFunnelRouter: RouterFeatureInterface<
  'MPHiringFunnelRouter',
  '/dashboard/mp-hiring-funnel'
> = {
  name: 'MPHiringFunnelRouter',
  Component: MPHiringFunnel,
  path: '/dashboard/mp-hiring-funnel',
  routeType: 'PRIVATE',
  allowedRoles: [],
  isDashboard: true,
  rbacPageID: MPHiringFunnelRBAC.pageID,
}

export const PrioritizationSettingRouter: RouterFeatureInterface<
  'PrioritizationSettingRouter',
  '/dashboard/mp-hiring-funnel/prioritization-setting'
> = {
  name: 'PrioritizationSettingRouter',
  Component: PrioritizationSetting,
  path: '/dashboard/mp-hiring-funnel/prioritization-setting',
  routeType: 'PRIVATE',
  allowedRoles: [],
  isDashboard: true,
  rbacPageID: PrioritizationSettingRBAC.pageID,
}

export const HiringFunnelBulkUploadRouter: RouterFeatureInterface<
  'HiringFunnelBulkUploadRouter',
  '/dashboard/mp-hiring-funnel/bulk-upload'
> = {
  name: 'HiringFunnelBulkUploadRouter',
  Component: () => <ManPowerBulkUpload type="hiringFunnel" />,
  path: '/dashboard/mp-hiring-funnel/bulk-upload',
  routeType: 'PRIVATE',
  allowedRoles: [],
  isDashboard: true,
}

const HRISAccountRouter = [
  MPStaffRouter,
  MPHiringFunnelRouter,
  PrioritizationSettingRouter,
  HiringFunnelBulkUploadRouter,
]

export const HRISAccountRouterDetail = generateRouterDetail(HRISAccountRouter)

export default HRISAccountRouter
