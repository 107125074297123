import { createAsyncThunk } from '@reduxjs/toolkit'

import { callErrorMsg } from 'helpers/errorMsg'
import { AppDispatchType } from 'store'
import { toastSuccess } from 'utils/toast'

import {
  getAllLocationsAPI,
  getHiringFunnelAPI,
  getAssignPICAPI,
  createHiringFunnelAPI,
  postAssignPICAPI,
  getPrioritizationSettingAPI,
  postPrioritizationSettingAPI,
  putPrioritizationSettingAPI,
  deletePrioritizationSettingAPI,
  putChangeHiringStatusAPI,
  putChangeStatusAPI,
  type FetchHiringFunnelQueryType,
  type CreateHiringFunnelPayloadType,
  type PostAssignPICPayloadType,
  type PrioritizationSettingType,
  type ChangeStatusPayloadType,
} from '../../services'
import {
  sliceName,
  setLoading,
  setLocations,
  setHiringFunnelData,
  setQuery,
  setAssignPIC,
  setPopUpState,
  setPrioritizationSetttingData,
} from './mpHiringFunnelSlice'

export const actSetQuery =
  (query: FetchHiringFunnelQueryType) => async (dispatch: AppDispatchType) => {
    await dispatch(setQuery(query))
    dispatch(fetchHiringFunnel())
  }

export const fetchHiringFunnel = createAsyncThunk(
  `${sliceName}/fetchHiringFunnel`,
  async (_, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true))
      const {
        mpHiringFunnel: { query },
      } = getState() as StoreStateType

      const { data } = await getHiringFunnelAPI(query)
      dispatch(setHiringFunnelData(data))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const postHiringFunnel = createAsyncThunk(
  `${sliceName}/postHiringFunnel`,
  async (payload: CreateHiringFunnelPayloadType, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const { data } = await createHiringFunnelAPI(payload)
      dispatch(setQuery({ page_index: 1, page_size: 10 }))
      dispatch(setPopUpState({ type: '' }))
      toastSuccess(data.message || 'Job ID berhasil dibuat')
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const fetchAssignPIC = createAsyncThunk(
  `${sliceName}/fetchAssignPIC`,
  async (_, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const { data } = await getAssignPICAPI()
      dispatch(setAssignPIC(data?.data?.users || []))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const postAssignPIC = createAsyncThunk(
  `${sliceName}/postAssignPIC`,
  async (payload: PostAssignPICPayloadType, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const { data } = await postAssignPICAPI(payload)
      dispatch(fetchHiringFunnel())
      dispatch(setPopUpState({ type: '' }))
      toastSuccess(data.message)
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const fetchPrioritizationSetting = createAsyncThunk(
  `${sliceName}/fetchPrioritizationSetting`,
  async (query: FetchHiringFunnelQueryType, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const { data } = await getPrioritizationSettingAPI(query)
      dispatch(setPrioritizationSetttingData(data))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const postPrioritizationSetting = createAsyncThunk(
  `${sliceName}/postPrioritizationSetting`,
  async ({ payload, cb }: { payload: PrioritizationSettingType; cb: () => void }, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const { data } = await postPrioritizationSettingAPI(payload)
      dispatch(fetchPrioritizationSetting({ page_index: 1, page_size: 10 }))
      dispatch(setPopUpState({ type: '' }))
      toastSuccess(data.message)
      cb()
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const putPrioritizationSetting = createAsyncThunk(
  `${sliceName}/putPrioritizationSetting`,
  async (
    { payload, query }: { payload: PrioritizationSettingType; query: FetchHiringFunnelQueryType },
    { dispatch },
  ) => {
    try {
      dispatch(setLoading(true))
      const { data } = await putPrioritizationSettingAPI(payload)
      dispatch(fetchPrioritizationSetting(query))
      dispatch(setPopUpState({ type: '' }))
      toastSuccess(data.message)
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const deletePrioritizationSetting = createAsyncThunk(
  `${sliceName}/deletePrioritizationSetting`,
  async ({ id, query }: { id: number; query: FetchHiringFunnelQueryType }, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const { data } = await deletePrioritizationSettingAPI(id)
      dispatch(fetchPrioritizationSetting(query))
      dispatch(setPopUpState({ type: '' }))
      toastSuccess(data.message)
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const fetchAllLocations = createAsyncThunk(
  `${sliceName}/getAllLocations`,
  async (_, { dispatch }) => {
    try {
      const { data } = await getAllLocationsAPI()
      dispatch(setLocations(data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const putChangeStatus = createAsyncThunk(
  `${sliceName}/putChangeHiringStatus`,
  async (
    { payload, isHiringStatus }: { payload: ChangeStatusPayloadType; isHiringStatus: boolean },
    { dispatch },
  ) => {
    try {
      dispatch(setLoading(true))
      let message = ''
      if (isHiringStatus) {
        const { data } = await putChangeHiringStatusAPI(payload)
        message = data.message
      } else {
        const { data } = await putChangeStatusAPI(payload)
        message = data.message
      }
      toastSuccess(message)
      dispatch(fetchHiringFunnel())
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)
