import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  type LocationType,
  type PrioritizationSettingType,
  type HiringFunnelType,
  type HiringFunnelPaginationType,
  type AssignPICType,
  type FetchHiringFunnelQueryType,
  type GetHiringFunnelResponseInterface,
  type GetAssignPICResponseInterface,
  type GetPrioritizationSettingResponseInterface,
} from '../../services'

export interface InitialStateInterface {
  isLoading: boolean
  locations: LocationType[]
  popUpState: {
    type:
      | 'create-job-request'
      | 'assign-pic'
      | 'create-prioritization'
      | 'edit-prioritization'
      | 'delete-prioritization'
      | ''
    jobIds?: { id: number; job_id: string }[]
    prioritizationData?: PrioritizationSettingType
  }
  hiringFunnelData: HiringFunnelType[]
  pagination: HiringFunnelPaginationType
  prioritizationSettingData: PrioritizationSettingType[]
  query: FetchHiringFunnelQueryType
  assignPICList: AssignPICType[]
}

const initialState: InitialStateInterface = {
  isLoading: false,
  locations: [],
  popUpState: {
    type: '',
    jobIds: [],
  },
  prioritizationSettingData: [],
  hiringFunnelData: [],
  pagination: {} as HiringFunnelPaginationType,
  query: {
    page_index: 1,
    page_size: 10,
    direction: 'desc',
    location_id: [],
  },
  assignPICList: [],
}

export const sliceName = 'mpHiringFunnel'

const mpHiringFunnel = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setLocations: (state, action: PayloadAction<LocationType[]>) => {
      state.locations = action.payload
    },
    setPopUpState: (state, action: PayloadAction<InitialStateInterface['popUpState']>) => {
      state.popUpState = action.payload
    },
    setQuery: (state, action: PayloadAction<FetchHiringFunnelQueryType>) => {
      state.query = action.payload
    },
    setHiringFunnelData: (state, action: PayloadAction<GetHiringFunnelResponseInterface>) => {
      state.hiringFunnelData = action.payload.data
      state.pagination = action.payload.pagination
    },
    setAssignPIC: (
      state,
      action: PayloadAction<GetAssignPICResponseInterface['data']['users']>,
    ) => {
      state.assignPICList = action.payload
    },
    setPrioritizationSetttingData: (
      state,
      action: PayloadAction<GetPrioritizationSettingResponseInterface>,
    ) => {
      state.prioritizationSettingData =
        action.payload.data?.map((item) => ({
          ...item,
          weight: Math.round(item.weight * 10) / 10,
        })) || []
      state.pagination = action.payload.pagination
    },
  },
})

export const {
  setLoading,
  setLocations,
  setPopUpState,
  setQuery,
  setHiringFunnelData,
  setAssignPIC,
  setPrioritizationSetttingData,
} = mpHiringFunnel.actions

export default mpHiringFunnel.reducer
